import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CoreHelpersTokenService } from "../../helpers/token/core-helpers-token.service";
import { UserModel } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CoreServicesAccountService {
  private userSubject$: BehaviorSubject<UserModel>;
  public user$: Observable<UserModel>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private tokenHelper: CoreHelpersTokenService
  ) {
    const userData: string | null = localStorage.getItem('user');
    this.userSubject$ = new BehaviorSubject<UserModel>(JSON.parse(userData != null ? userData : 'null'));
    this.user$ = this.userSubject$.asObservable();
  }

  public get userValue(): UserModel {
    return this.userSubject$.value;
  }

  async login(username: string, password: string): Promise<UserModel> {
    const token = await this.tokenHelper.processCsrfToken();
    const data = JSON.stringify({username, password});
    const options: any = {
      headers: {
        'X-XSRF-TOKEN': token,
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true
    };

    return new Promise((resolve, reject) => {
      if (token == null) {
        reject('Null token');
      } else {
        this.http.post<UserModel>(`${environment.url}users/authenticate`, data, options)
          .subscribe((user: any) => {
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject$.next(user);
            resolve(user);
            console.log(user)
        });
      }
    });
  }

  logout(): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject$.next(null as any);
    // this.router.navigate(['/account/login']).then(() => {});
    this.tokenHelper.clear();
    this.router.navigate(['/login']).then(() => {});
  }

  recoverPassword(username: string): Observable<string> {
    const data = JSON.stringify({ username });

    return this.http.post<string>(`${environment.url}users/recover`, data)
      .pipe(map(result => {
        return result;
      }));
  }

  register(user: UserModel): Observable<any> {
    return this.http.post(`${environment.url}/users/register`, user);
  }

  getAll(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${environment.url}/users`);
  }

  getById(id: string): Observable<UserModel> {
    return this.http.get<UserModel>(`${environment.url}/users/${id}`);
  }

  update(id: number, params: any): Observable<any> {
    return this.http.put(`${environment.url}/users/${id}`, params)
      .pipe(map((x) => {
        // update stored user if the logged in user updated their own record
        if (id === this.userValue.id) {
          // update local storage
          const user = { ...this.userValue, ...params };
          localStorage.setItem('user', JSON.stringify(user));

          // publish updated user to subscribers
          this.userSubject$.next(user);
        }
        return x;
      }));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.url}/users/${id}`)
      .pipe(map(x => {
        // auto logout if the logged in user deleted their own record
        if (id === this.userValue.id) {
          this.logout();
        }
        return x;
      }));
  }
}
