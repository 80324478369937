import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsLogosGlaucioComponent } from './components/logos/glaucio/shared-components-logos-glaucio.component';
import { SharedComponentsModalActionsItemComponent } from "./components/modal/actions/item/shared-components-modal-actions-item.component";
import { SharedComponentsModalActionsComponent } from "./components/modal/actions/shared-components-modal-actions.component";

@NgModule({
  declarations: [
    SharedComponentsLogosGlaucioComponent,
    SharedComponentsModalActionsComponent,
    SharedComponentsModalActionsItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SharedComponentsLogosGlaucioComponent,
    SharedComponentsModalActionsComponent
  ]
})
export class SharedModule { }
