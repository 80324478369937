import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CoreServicesAccountService } from '../../services/account/core-services-account.service';
import { CoreHelpersTokenService } from "../token/core-helpers-token.service";
import { TCanActiveResult } from './auth.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _account: CoreServicesAccountService,
    private _token: CoreHelpersTokenService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): TCanActiveResult {
    let result = false;

    const user = this._account.userValue;
    if (user != null) {
      const userName = user.username != null && user.username.length > 0;
      const token = user.token != null && user.token.length > 0;
      const hasRequiredKeys = this._token.requiredTokens();

      result = userName && token && hasRequiredKeys;
    }

    if (result) {
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this._router.navigate(
        ['/login'], {
          queryParams: {
            returnUrl: state.url }
        }
      )
        .then(() => {
          console.log('User not logged in');
          this._token.clear();
          return false;
        });

      return false;
    }
  }
}
