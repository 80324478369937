import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import Cookies from "js-cookie";

import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CoreHelpersTokenService {

  constructor(private http: HttpClient) { }

  async processCsrfToken(): Promise<string | null> {
    const headers = new HttpHeaders();
    headers.append('accept', 'application/json');
    headers.append('content-type', 'application/json');
    headers.append('X-Requested-With', 'XMLHttpRequest');

    return new Promise((resolve, reject) => {
      this.http.get(`${environment.csrfUrl}`, { withCredentials: true, responseType: "json", headers })
        .subscribe(
          () => {
          const token = Cookies.get('XSRF-TOKEN');

          if (token == null) {
            resolve(null)
          } else {
            Cookies.set('X-XSRF-TOKEN', token)
            resolve(token);
          }
        },
        error => {
            reject(error)
        }
      );
    })
  }

  clear() {
    localStorage.removeItem('user');

    const cookies = Cookies.get() as object;
    const keys = Object.keys(cookies);

    for (const key of keys) {
      Cookies.remove(key);
    }
  }

  requiredTokens(): boolean {
    const cookies = Cookies.get() as any;
    const keys = Object.keys(cookies);

    const requiredKeys = ['XSRF-TOKEN', 'X-XSRF-TOKEN']

    const hasKey1 = keys.filter(x => x === requiredKeys[0]).length > 0;
    const hasKey2 = keys.filter(x => x === requiredKeys[1]).length > 0;

    return  hasKey1 && hasKey2;
  }
}
