import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { GlNgButtonsFrontendModule } from "gl-ng-buttons-frontend";
import { GlNgFooterFrontendModule } from "gl-ng-footer-frontend";
import { GlNgHeadersFrontendModule } from "gl-ng-headers-frontend";
import { GlNgInputsFrontendModule } from "gl-ng-inputs-frontend";
import { GlNgLogosFrontendModule } from "gl-ng-logos-frontend";
import { GlNgMenusFrontendModule } from "gl-ng-menus-frontend";
import { GlNgModalsFrontendModule } from "gl-ng-modals-frontend";
import { GlNgOutputsFrontendModule } from "gl-ng-outputs-frontend";
import { GlNgTitlesFrontendModule } from "gl-ng-titles-frontend";
import { LottieModule } from "ngx-lottie";
import { SharedModule } from "../shared/shared.module";
import { ViewsHomeComponent } from './home/views-home.component';
import { ViewsLoginComponent } from './login/views-login.component';
import { ViewsRegisterComponent } from './register/views-register.component';



@NgModule({
  declarations: [
    ViewsHomeComponent,
    ViewsLoginComponent,
    ViewsRegisterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    LottieModule,
    SharedModule,
    GlNgInputsFrontendModule,
    GlNgModalsFrontendModule,
    GlNgFooterFrontendModule,
    GlNgMenusFrontendModule,
    GlNgHeadersFrontendModule,
    GlNgLogosFrontendModule,
    GlNgButtonsFrontendModule,
    GlNgTitlesFrontendModule,
    GlNgOutputsFrontendModule
  ]
})
export class ViewsModule { }
