import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LottieModule } from "ngx-lottie";

import { AppComponent } from './app.component';
import { ErrorInterceptor } from './core/helpers/error/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt-interceptor/jwt.interceptor';
import player, { LottiePlayer } from "lottie-web";
import { BrowserViewPort } from "gl-w-frontend";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ViewsModule } from "./views/views.module";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { ReactiveFormsModule } from "@angular/forms";

// Note we need a separate function as it's required by the AOT compiler.
export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,

    LottieModule.forRoot({player: playerFactory}),

    CoreModule,
    SharedModule,
    ViewsModule,
    RouterModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() {
    BrowserViewPort.setFullHeightListener();
    BrowserViewPort.setFullWidthListener();
  }
}
