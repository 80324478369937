import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/helpers/auth/auth.guard';
import { ViewsHomeComponent } from './views/home/views-home.component';

const accountModule = () => import('./core/account/account.module').then(x => x.AccountModule);
// const usersModule = () => import('./core/users/users.module').then(x => x.UsersModule);

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const routes: Routes = [
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    // We need a component here because we cannot define the route otherwise
    component: ViewsHomeComponent,
    canActivate: [AuthGuard]
  },

  {
    path: '',
    component: ViewsHomeComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'manage-portfolio/:type',
  //   component: ViewsManagePortfolioComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     action: 'list'
  //   } as IRouteData
  // },
  // {
  //   path: 'manage-portfolio/:type/:id',
  //   component: ViewsManagePortfolioComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     action: 'preview'
  //   } as IRouteData
  // },
  // {
  //   path: 'manage-portfolio',
  //   component: ViewsManagePortfolioComponent,
  //   canActivate: [AuthGuard]
  // },
  // { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
  {
    path: '',
    loadChildren: accountModule
  },

  // otherwise redirect to home
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  declarations: [],
  imports: [
    [
      RouterModule.forRoot(
        routes,
        { onSameUrlNavigation: 'reload', enableTracing: false, relativeLinkResolution: 'legacy' }
      )
    ],
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');

        if (externalUrl != null) {
          window.open(externalUrl, '_blank');
        }
      },
    }
  ]
})
export class AppRoutingModule { }
