import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CoreServicesAccountService } from '../../services/account/core-services-account.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _account: CoreServicesAccountService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this._account.userValue;
    const isLoggedIn = user && user.token;
    const isApiUrl = request.url.startsWith(environment.url);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.token}`
        }
      });
    }

    return next.handle(request);
  }
}
