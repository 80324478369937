import { Component } from '@angular/core';
import { UserModel } from './core/models/user.model';
import { CoreServicesAccountService } from "./core/services/account/core-services-account.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'R2 Fitness';
  user: UserModel | undefined;

  constructor(private _account: CoreServicesAccountService) {
    this._account.user$.subscribe(x => this.user = x);
  }

  logout(): void {
    this._account.logout();
  }
}
