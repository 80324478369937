import {IEnvironment} from "./environment.interface";

export const environment: IEnvironment = {
  environmentSystem: {
    version: '1.0.0',
    versionName: 'production'
  },
  production: true,
  url: 'https://r2fitness.glaucioleonardo.com.br/api/',
  csrfUrl: '/sanctum/csrf-cookie'
};
